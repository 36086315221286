<template>
  <div>
    <b-card title="Filtro">
      <b-row>
        <b-col>
          <label>Mês</label>
          <b-form-select :disabled="!rerender" v-model="selectedMonth" :options="monthOptions"></b-form-select>
        </b-col>
        <b-col>
          <label>Ano</label>
          <b-form-select :disabled="!rerender" v-model="selectedYear" :options="yearOptions"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card title="Base de cálculo">
      <b-row>
        <b-col>
          <h5 class="text-center mb-2">
            {{ `Depreciação de Bens - R$ ${numberToMonetary(response.totalGoods)}` }}
          </h5>
        </b-col>
        <b-col>
          <h5 class="text-center">
            {{ `Colaboradores - R$ ${numberToMonetary(response.totalUsersCosts)}` }}
          </h5>
        </b-col>
      </b-row>
    </b-card>

    <b-row class="mt-2">
      <b-col md="6">
        <b-card class="h-100" sub-title="Bens">
          <div v-if="goodsFiltered.length ==0">
            <b-alert show variant="primary">
              <p class="text-center">
                <br />
                Não existe <strong>Bens depreciáveis</strong> dentro do escopo deste escritório na data escolhida.
              </p>
              <br />
            </b-alert>
          </div>

          <b-row class="m-2" v-for="(good, index) in goodsFiltered" :key="index">
            <b-col class="mt-1">
              <b-badge variant="primary">{{ good.item_name }}</b-badge
              ><br />
              <span style="font-size: 10px">Vida útil: {{ good.remainingLifespan }}meses</span>
            </b-col>
            <b-col>
              <label for="">Valor</label>
              <b-form-input disabled v-money="moneyMask" v-model="good.acquisition_value"></b-form-input>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col md="6">
        <b-card class="h-100" sub-title="Colaboradores">
          <b-row class="m-1" v-for="(employee, index) in response.usersWithCosts" :key="index">
            <b-col cols="12">
              <b-badge variant="primary" class="">{{ employee.username }}</b-badge
              ><br />
            </b-col>
            <b-col cols="12">
              <b-form-input v-money="moneyMask" disabled :value="employee.value"></b-form-input>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col>
        <b-card>
          <b-row>
            <b-col class="text-right">
              <b-button variant="success" @click="calcCosts">Calcular</b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BFormSelect, BBadge, BButton, BAlert } from 'bootstrap-vue';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
    BBadge,
    BButton,
    BAlert
  },
  data: () => ({
    response: { totalUsersCosts: 0, totalGoods: 0 },
    yearOptions: [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2040],
    monthOptions: [
      { value: 1, text: 'Janeiro' },
      { value: 2, text: 'Fevereiro' },
      { value: 3, text: 'Março' },
      { value: 4, text: 'Abril' },
      { value: 5, text: 'Maio' },
      { value: 6, text: 'Junho' },
      { value: 7, text: 'Julho' },
      { value: 8, text: 'Agosto' },
      { value: 9, text: 'Setembro' },
      { value: 10, text: 'Outubro' },
      { value: 11, text: 'Novembro' },
      { value: 12, text: 'Dezembro' }
    ],
    selectedMonth: 1,
    selectedYear: new Date().getFullYear(),
    rerender: true,
    moneyMask: {
      prefix: 'R$ ',
      suffix: '',
      thousands: '.',
      decimal: ',',
      precision: 2,
      masked: false
    },
    elapsedMonths: 0,
    goodsFiltered: []
  }),
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  },
  created() {
    this.getCalcCosts();
  },
  watch: {
    selectedMonth: 'getCalcCosts',
    selectedYear: 'getCalcCosts'
  },
  methods: {
    async getCalcCosts() {
      this.rerender = false;
      if (!this.userList) {
        this.$store
          .dispatch('getCalcCosts', {
            workspace_id: this.$store.getters.currentWorkspace.id,
            month: this.selectedMonth,
            year: this.selectedYear
          })
          .then(resp => {
            this.rerender = true;

            this.response.goods = resp.goods.map(good => {
              const acquisitionDate = new Date(good.acquisition_date);
              const lifespanMonths = good.lifespan_months;
              const currentDate = new Date(this.selectedYear, this.selectedMonth - 1);

              const monthsElapsed =
                (currentDate.getFullYear() - acquisitionDate.getFullYear()) * 12 +
                (currentDate.getMonth() - acquisitionDate.getMonth());

              this.elapsedMonths = monthsElapsed;

              good.remainingLifespan = lifespanMonths - monthsElapsed
              return good;
            });

            this.goodsFiltered = this.response.goods.filter(item => item.lifespan_months >= item.remainingLifespan);
            this.response = resp;
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        this.getInactivesUsers();
      }
    },
    calcCosts() {
      this.$store.dispatch('saveGoodsFiltered', this.goodsFiltered);
      this.$store.dispatch('saveResponse', this.response);

      this.$router.push({ name: 'cost-per-tasks' });
    }
  }
};
</script>
